$post-padding: 1rem;
$post-header-vertical-padding: 1rem;
$post-border-radius: 8px;

.posts-listing {
  margin-bottom: 3rem;

  .load-more {
    width: 100%;
    margin-top: 2rem;
  }
}

.post-composer {
  max-width: 500px;

  .p-inputtextarea {
    width: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin-bottom: 1rem;

    &.is-large {
      font-size: 1.5rem;
    }
  }

  .form-actions .p-dropdown {
    width: 220px;
  }

  .media-preview {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      flex: none;

      img {
        width: 12.5rem;
        object-fit: cover;
        aspect-ratio: 1 / 1;
        vertical-align: top;
      }

      &:hover {
        .media-preview-remove {
          opacity: 1;
        }
      }
    }

    &-remove {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      opacity: 0;
      background-color: rgba(0,0,0, .5);
      color: #fff !important;
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    z-index: 15;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: var(--surface-0);
      opacity: 0.6;
      content: '';
    }
  }

  .post-share {
    margin: 2rem 0 0;

    .p-multiselect {
      width: 100%;
    }
  }
}

.post-item {
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  margin-bottom: 16px;
  border-radius: $post-border-radius;
  border: 1px solid var(--border-color-light);

  .post-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $post-header-vertical-padding $post-padding;
    box-sizing: border-box;

    .p-avatar {
      align-self: flex-start;
      margin-right: 10px;
    }

    .post-header-details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      height: 100%;
    }

    .post-header-author {
      line-height: 1.5;
      font-weight: 800;
    }

    .post-header-time {
      font-size: .75rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .post-media {
    margin-bottom: 1rem
  }

  .post-content {
    padding: 0 $post-padding $post-padding;

    &.is-large {
      font-size: 1.5rem;
    }
  }

  .post-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .post-footer {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.42857;

    .post-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      border-top: 1px solid #e5e5e5;
    }

    .post-footer-action {
      flex: 1;

      .p-button {
        width: 100%;
        align-items: center;
        justify-content: center;

        .p-button-label {
          flex: none
        }
      }
    }
  }
}

.reactions-popover {
  border-radius: 50px;
  margin: 0;

  .p-button-group {
    gap: 0;
  }

  .reaction-button {
    font-size: 2rem;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50px;
  }
}

.post-comments {
  border-top: 1px solid #e5e5e5;

}

.post-comments-composer {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 0.75rem $post-padding;
  

    .p-inputtext {
      margin-left: 1rem;
      flex: 1;
      padding: 0.5rem;
    }

    .p-inputgroup-addon {
      padding: 0;
    }

    .p-button {
      padding: 0;
    } 
}

.post-comments-body {
    height: 100%;
    min-height: auto;
    overflow: hidden auto;
    padding: 0 $post-padding;
}


.post-comment {
    display: flex;
    flex-direction: row;
    padding: 1rem 0px;
    border-top: 1px solid rgba(0,0,0, .13);
    gap: 1rem;
}

.post-comment-wrapper {
  display: flex;
  flex-direction: column;
}


.post-comment-header {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 0.5rem;

  .p-avatar {
    align-self: flex-start;
    margin-right: 10px;
  }

  .comment-header-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    height: 100%;
  }

  .comment-header-author {
    line-height: 1.5;
    font-weight: 800;
  }

  .comment-header-time {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.5;
  }
}